import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { InfoCircle } from "iconsax-react";
import {
  PlatformSyscodeString,
  PLATFORM_SYSCODE,
} from "../../../../commons/constants/platformTypeSyscode";
import generateHmacHeaders from "../components/generateHmac";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const HmacSimulatorLayout = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [optionLoad, setOptionLoad] = useState(true);
  const [requestUrl, setRequestUrl] = useState();
  const [load, setLoad] = useState(false);
  const [responseHeaders, setResponseHeaders] = useState([]);
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});

  const requestMethodOptions = [
    { value: "GET", label: "GET" },
    { value: "POST", label: "POST" },
    { value: "PUT", label: "PUT" },
    { value: "DELETE", label: "DELETE" },
  ];

  const platformTypeOptions = Object.entries(PLATFORM_SYSCODE).map(
    ([key, value]) => ({
      value: value,
      label: PlatformSyscodeString[value],
    })
  );

  function formatData(data) {
    var newData = data;
    const jsonInput = newData.requestBody;
    const requestMethod = newData.requestMethod.value;
    const developerPlatformSyscode = newData.developerPlatformSyscode.value;

    try {
      // Try parsing the JSON input
      const parsedJson = JSON.parse(jsonInput);
      if (
        typeof parsedJson !== "object" ||
        Array.isArray(parsedJson) ||
        parsedJson === null
      ) {
        throw new Error("The JSON must be an object (e.g., {})."); // Trigger the catch block
      }

      newData.requestBody = parsedJson;
    } catch (error) {
      // If parsing fails, set an error message or show feedback to the user
      console.error("Invalid JSON format:", error);
      setIsDisabled(false);
      setError("requestBody", {
        type: "manual",
        message: "Invalid JSON format.",
      });
      return null;
    }
    const check = isValidIsoDateTime(newData.isoDatetime);
    if (!check) {
      setIsDisabled(false);
      setError("isoDatetime", {
        type: "manual",
        message: "Invalid datetime format.",
      });
      return null;
    }
    const parsedUrl = new URL(data.requestUrl);
    const requestHost = parsedUrl.host;
    const requestUrlPathQuery = `${parsedUrl.pathname}${parsedUrl.search}`;

    newData.requestHost = requestHost;
    newData.requestUrlPathQuery = requestUrlPathQuery;
    newData.requestMethod = requestMethod;
    newData.developerPlatformSyscode = developerPlatformSyscode;
    return newData;
  }

  function isValidIsoDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toISOString() === dateTimeString;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button

    const newData = formatData(data);

    if (newData) {
      const headers = generateHmacHeaders(
        newData.requestHost,
        newData.requestMethod,
        newData.requestUrlPathQuery,
        newData.requestBody,
        newData.developerPlatformSyscode,
        newData.developerApiKey,
        newData.developerProjectId,
        newData.developerSecretKey,
        newData.isoDatetime
      );
      setResponseHeaders(headers);
      setIsDisabled(false);
    }
  };

  if (!load) {
    return (
      <div
        className="flex justify-center items-center min-h-screen"
        style={{ marginTop: "-1.5%" }}
      >
        <div
          className="mt-16 desktop:space-x-8  mobile:pt-2 mobile:pb-4 mobile:space-x-2"
          style={{
            textAlign: "-webkit-center",
            marginTop: "0px",
            width: "100%",
          }}
        >
          <div
            className="bg-white border-white border rounded-2xl m-5 p-4"
            style={{
              height: "inherit",
              width: "65%",
              minHeight: 0,
              minWidth: 0,
            }}
          >
            <div className="mt-4 mb-8 text-center">
              <h1
                className="text-2xl font-semibold text-black"
                style={{ fontSize: "25px" }}
              >
                This simulator may be used to verify developer project API HTTP
                POST|GET|PUT|DELETE Headers
              </h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="mt-8 sm:col-span-2" style={{ color: "black" }}>
                  <label
                    htmlFor="requestMethod"
                    className="block text-sm font-medium text-gray-600 mt-8"
                    style={{ textAlign: "left", marginBottom: "5px" }}
                  >
                    Request Method
                  </label>
                  <Controller
                    name="requestMethod"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        isClearable
                        {...field}
                        options={requestMethodOptions}
                        isSearchable={true}
                        isMulti={false}
                        placeholder="Select API"
                        className="text-sm"
                      />
                    )}
                  />
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="requestUrl"
                    className="block text-sm font-medium text-gray-600 mt-4"
                    style={{ textAlign: "left", marginBottom: "5px" }}
                  >
                    Request URL
                  </label>
                  <div
                    className="relative rounded-md shadow-sm"
                    style={{ color: "black" }}
                  >
                    <input
                      type="text"
                      name="requestUrl"
                      id="requestUrl"
                      className={
                        !errors.requestUrl
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder="Enter Request URL"
                      {...register("requestUrl", { required: true })}
                      value={requestUrl}
                      onChange={(e) => setRequestUrl(e.target.value)}
                    />
                    {errors.requestUrl?.type === "required" && errorIcon}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="requestBody"
                  className="block text-sm font-medium text-gray-600 mt-4"
                  style={{
                    textAlign: "left",
                    marginBottom: "5px",
                    marginTop: "-10px",
                  }}
                >
                  JSON Request Body
                </label>
                <div className="relative rounded-md shadow-sm text-black">
                  <textarea
                    name="requestBody"
                    id="requestBody"
                    rows="8" // Adjust the height as needed
                    className={
                      !errors.requestBody
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter JSON Request Body"
                    {...register("requestBody", {
                      required: true,
                    })}
                    style={{
                      fontFamily: "monospace", // Optional: to improve JSON readability
                      resize: "vertical", // Allow users to resize vertically only
                    }}
                  ></textarea>
                  {errors.requestBody?.type === "required" && errorIcon}
                </div>
                {errors.requestBody?.message && (
                  <>
                    <div>
                      <p className="block text-sm text-red-600">
                        {errors.requestBody.message}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-2">
                <label
                  htmlFor="developerApiKey"
                  className="block text-sm font-medium text-gray-600 mt-4"
                  style={{
                    textAlign: "left",
                    marginBottom: "5px",
                    marginTop: "-10px",
                  }}
                >
                  Developer API Key
                </label>
                <div className="relative rounded-md shadow-sm mb-2 text-black">
                  <input
                    type="text"
                    name="developerApiKey"
                    id="developerApiKey"
                    className={
                      !errors.developerApiKey
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter Developer API Key"
                    {...register("developerApiKey", {
                      required: true,
                    })}
                    style={{ marginBottom: "20px" }}
                  />
                  {errors.developerApiKey?.type === "required" && errorIcon}
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="developerProjectId"
                  className="block text-sm font-medium text-gray-600 mt-4"
                  style={{
                    textAlign: "left",
                    marginBottom: "5px",
                    marginTop: "-10px",
                  }}
                >
                  Developer Project ID
                </label>
                <div className="relative rounded-md shadow-sm mb-2 text-black">
                  <input
                    type="text"
                    name="developerProjectId"
                    id="developerProjectId"
                    className={
                      !errors.developerProjectId
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter Developer Project ID"
                    {...register("developerProjectId", {
                      required: true,
                    })}
                    style={{ marginBottom: "20px" }}
                  />
                  {errors.developerProjectId?.type === "required" && errorIcon}
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="developerSecretKey"
                  className="block text-sm font-medium text-gray-600 mt-4"
                  style={{
                    textAlign: "left",
                    marginBottom: "5px",
                    marginTop: "-10px",
                  }}
                >
                  Developer Project Secret Key
                </label>
                <div className="relative rounded-md shadow-sm mb-2 text-black">
                  <input
                    type="text"
                    name="developerSecretKey"
                    id="developerSecretKey"
                    className={
                      !errors.developerSecretKey
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter Developer Project Secret Key"
                    {...register("developerSecretKey", {
                      required: true,
                    })}
                    style={{ marginBottom: "20px" }}
                  />
                  {errors.developerSecretKey?.type === "required" && errorIcon}
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="isoDatetime"
                  className="block text-sm font-medium text-gray-600 mt-4"
                  style={{
                    textAlign: "left",
                    marginBottom: "5px",
                    marginTop: "-10px",
                  }}
                >
                  ISO Datetime (Eg. 2024-11-08T08:00:00.000Z)
                </label>
                <div className="relative rounded-md shadow-sm mb-2 text-black">
                  <input
                    type="text"
                    name="isoDatetime"
                    id="isoDatetime"
                    className={
                      !errors.isoDatetime
                        ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                        : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                    }
                    placeholder="Enter ISO Datetime"
                    {...register("isoDatetime", {
                      required: true,
                    })}
                  />
                  {errors.isoDatetime?.type === "required" && errorIcon}
                </div>
                {errors.isoDatetime?.message && (
                  <>
                    <div>
                      <p className="block text-sm text-red-600">
                        {errors.isoDatetime.message}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-2">
                <label
                  htmlFor="developerPlatformSyscode"
                  className="block text-sm font-medium text-gray-600 mt-4"
                  style={{ textAlign: "left", marginBottom: "5px" }}
                >
                  Platform Type
                </label>
                <div className="sm:col-span-2" style={{ color: "black" }}>
                  <Controller
                    name="developerPlatformSyscode"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        isClearable
                        {...field}
                        options={platformTypeOptions}
                        isSearchable={true}
                        placeholder="Select Platform Type"
                        className="text-sm"
                      />
                    )}
                  />
                </div>
              </div>

              {/* create button */}
              <div>
                <button
                  type="submit"
                  className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mt-10"
                  disabled={isDisabled}
                >
                  Generate
                </button>
              </div>
              {responseHeaders.length > 0 && (
                <div className="mt-4">
                  <h3
                    className="text-lg font-bold text-gray-800"
                    style={{ fontSize: "27px" }}
                  >
                    HMAC-SHA256 Headers:
                  </h3>
                  <ul className="mt-2 space-y-4 text-left">
                    {responseHeaders.map((header, index) => (
                      <li key={index}>
                        {/* Header title styled as section header */}
                        <div className="text-md font-semibold text-gray-900" style={{ fontSize: "20px" }}>
                          {header.header}
                        </div>
                        {/* Header value styled below with indent */}
                        <div className="text-sm text-gray-700" style={{ fontSize: "15px" }}>
                          {header.value}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default HmacSimulatorLayout;
