import { sha256 } from "js-sha256";
import { Buffer } from 'buffer';

export default function generateHmacHeaders(
  host,
  method, // GET, PUT, POST, DELETE
  urlPathQuery, // path+query
  body, // request body (undefined of none)
  platformSyscode,
  apiKey, // access key id
  projectId,
  secretKey,
  isoDatetime
) {
  if (!body) {
    body = {};
  }
  var verb = method.toUpperCase();
  var isoNow = isoDatetime;
  var contentHash = Buffer.from(
    sha256.create().update(JSON.stringify(body)).array()
  ).toString("base64");

  // Signed Headers
  var signedHeaders =
    "datetime|host|project-id|platform-syscode|content-sha256";

  // String-To-Sign
  var stringToSign =
    verb +
    "\n" + // VERB
    urlPathQuery +
    "\n" + // path_and_query
    isoNow +
    "|" +
    host +
    "|" +
    projectId +
    "|" +
    platformSyscode +
    "|" +
    contentHash; // Semicolon separated SignedHeaders values

  // Signature
  var signature = Buffer.from(
    sha256.create().update(`${stringToSign}${secretKey}`).array()
  ).toString("base64");

  // Result Request Headers
  return [
    { header: "datetime", value: isoNow },
    { header: "content-sha256", value: contentHash },
    {
      header: "Authorization",
      value:
        "HMAC-SHA256 Credential=" +
        apiKey +
        "&SignedHeaders=" +
        signedHeaders +
        "&Signature=" +
        signature,
    },
  ];
}